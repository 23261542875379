export default {
  data() {
    return {
      strings: {
        IT: {
          p1: "La procedura di installazione prevede i seguenti passi:",
          p2:
            "Clicca il tasto condividi che trovi sulla destra della barra degli indirizzi o in fondo allo schermo",
          p3:
            "Si aprirà un menu dal quale scegliere l'opzione Aggiungi alla schermata Home",
          p4: "Il dispositivo chiederà conferma. Clicca sulla voce Aggiungi",
          p5:
            "Al termine dell'operazione il programma di navigazione si chiuderà automaticamente e l'icona di accesso sarà disponibile sul dispositivo",
        },
        EN: {
          p1: "The installation procedure involves the following steps:",
          p2:
            "Click on your phone’s share button which is either on the right of the address bar or at the bottom of the screen",
          p3: "From the menu choose Add to home page",
          p4: "Your device will ask you to confirm. Click on Add",
          p5:
            "After this, the installation program will automatically close and the granoduro.net icon will appear on your device",
        },
        EL: {
          p1: "Η διαδικασία εγκατάστασης περιλαμβάνει τα ακόλουθα βήματα",
          p2:
            "Κάντε κλικ στο κουμπί κοινή χρήση που βρίσκεται στα δεξιά της γραμμής διευθύνσεων ή στο κάτω μέρος της οθόνης",
          p3:
            "Θα ανοίξει ένα μενού από το οποίο θα επιλέξετε την επιλογή Προσθήκη στην οθόνη Αφετηρίας",
          p4:
            'Η συσκευή θα ζητήσει ξανά επιβεβαίωση της επιλογής "Προσθήκη στην οθόνη Αφετηρίας" κάνοντας κλικ στη λέξη Προσθήκη',
          p5:
            "Στο τέλος της λειτουργίας το πρόγραμμα πλοήγησης θα κλείσει αυτόματα και το εικονίδιο πρόσβασης στην υπηρεσία granoduro.net θα είναι διαθέσιμο στην οθόνη της συσκευής",
        },
      },
    };
  },
  computed: {
    s: {
      get() {
        let s = this.strings[this.globalLocale];
        if (s == undefined) s = this.strings["EN"];
        return s;
      },
    },
    p1: {
      get() {
        return this.s["p1"];
      },
    },
    p2: {
      get() {
        return this.s["p2"];
      },
    },
    p3: {
      get() {
        return this.s["p3"];
      },
    },
    p4: {
      get() {
        return this.s["p4"];
      },
    },
    p5: {
      get() {
        return this.s["p5"];
      },
    },
  },
};
