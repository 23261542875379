<template>
  <div class="bluBarilla bodyBarilla">
    <div id="spiga_vitruviana" class="panelImg">
      <img
        alt="spiga vitruviana"
        src="@/assets/images/spiga_vitruviana_barilla.jpg"
        class="mt5 spiga"
      />
    </div>
    <div id="logo_barilla" class="panelImg">
      <img
        alt="logo barilla"
        src="@/assets/images/logo_barilla.jpg"
        class="mt5 barilla"
      />
    </div>
    <div
      style="margin-top: 60px; margin-bottom: 60px; text-align: left"
      v-if="!isInstalled"
    >
      <h3 style="margin: 0 10px">
        {{ p1 }}
      </h3>
      <ol style="padding: 10px">
        <li>
          {{ p2 }}
          <img
            alt="tasto condividi"
            src="@/assets/images/share.png"
            style="width: 25px"
          />
        </li>
        <li>
          {{ p3 }}
        </li>
        <li>
          {{ p4 }}
        </li>
        <li>
          {{ p5 }}
        </li>
      </ol>
    </div>
    <div id="logo_horta" class="panelImg">
      <img
        alt="logo horta"
        src="@/assets/images/logo_horta_bianco.png"
        class="mt5 barilla"
      />
    </div>
  </div>
</template>

<script>
import locales from "../mixins/locales.js";

export default {
  name: "HelloWorld",
  mixins: [locales],
  data() {
    return {
      isInstalled: true,
      globalLocale: "IT",
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get("lang");

    if (lang != null) this.globalLocale = lang;
    if (window.matchMedia("(display-mode: standalone)").matches) {
      //const url = "http://granoduro-pocket.horta-srl.it/";
      const url =
        "https://cloudtest.horta-stg.basf.com/horta_cereali/faces/light/barilla/light_login.html?serv_id_in=FITOSPA06TESTBARILLA&lang=" +
        this.globalLocale;
      window.location.href = url;
    } else {
      this.isInstalled = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0;
}

li {
  margin: 0 10px;
}

li {
  list-style: decimal;
}

a {
  color: #42b983;
}

.iframe {
  height: 97vh;
}

.spiga {
  width: 60%;
  margin-top: 50px;
}

.barilla {
  width: 45%;
}

.bodyBarilla {
  margin: auto;
  max-width: 500px;
  color: white;
  font: 13px/1.5 Arial, Helvetica, sans-serif;
  display: table;
  height: 95vh;
}

.bluBarilla {
  background-color: rgb(68, 119, 185);
}
</style>
